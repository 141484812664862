<template>
  <div style="text-align: left">广告信息</div>
  <el-form
    :model="adform"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item
      label="广告标题"
      size="small"
      prop="caption"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          clearable
          v-model="adform.caption"
          style="width: 230px"
          placeholder="请输入广告标题"
        ></el-input>
      </div>
      <!-- <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >注意！广告只显示第一个</span
      > -->
    </el-form-item>
     <el-form-item
      label="广告位置"
      size="small"
      prop="caption"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-select
          clearable
          v-model="adform.location"
          style="width: 230px"
          placeholder="请输入广告位置"
        >
          <el-option
          v-for="(item,index) in locationOption"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></el-option>
        </el-select>
      </div>
      <!-- <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >注意！广告只显示第一个</span
      > -->
    </el-form-item>
    <el-form-item
      label="广告时间"
      prop="teamTimeproid"
      style="width: 50%; text-align: left"
      size="small"
    >
      <el-date-picker
        v-model="adform.teamTimeproid"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      label="广告媒体文件"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        :action="UploadUrl"
        :headers="{
          authorization: Token
        }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        accept=""
         :auto-upload="true"
        :before-upload="handlebeforupload"
        :file-list="teamlist"
        :on-success="filechange"
        :limit="1"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              v-if="!isvideo"
              :src="file.url"
              alt=""
            />
             <video
              class="el-upload-list__item-thumbnail"
              v-else
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比2：1、图片大小200k为宜，只可设置一个媒体文件,单张图片不可超过2M</span
      >
    </el-form-item>
    <el-form-item
      label="广告备注"
      prop="adopted"
      class="swipertype"
      style="width: 50%"
      size="small"
    >
      <div style="display: flex">
        <el-input
          clearable
          v-model="adform.note"
          style="width: 230px"
          placeholder="请输入广告备注"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="广告内容" size="small" style="width: 50%">
      <div style="display: flex">
        <el-input
          clearable
          style="width: 230px"
          v-model="adform.content"
          placeholder="请输入广告内容"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="广告详情" style="width: 80%">
      <vue-ueditor-wrap
        v-model="adform.description"
        :config="editorConfig"
        editor-id="adInfo"
      ></vue-ueditor-wrap>
    </el-form-item>

    <el-form-item label="是否展示" prop="adopted" style="width: 50%">
      <div class="displayflex" style="margin-top: 10px">
        <el-switch
          v-model="adform.adopted"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      adform: {
        caption: '',
        content: '',
        coverUrl: '',
        description: '',
        note: '',
        teamTimeproid: [],
        startDate: '',
        endDate: '',
        adopted: true,
        location: ''
      },
      locationOption: [],
      rules: {
        caption: [
          { required: true, message: '请输入广告主题！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        showDate: [
          { required: true, message: '请选取广告活动时间！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      typeoption: [{
        label: '自定义',
        value: 'BANNER'
      }, {
        label: '咨询公告',
        value: 'INFO'
      }, {
        label: '广告',
        value: 'AD'
      }, {
        label: '团购',
        value: 'GROUP'
      }, {
        label: '直播',
        value: 'LIVE'
      }],
      baseUrl: '',
      teamlist: [],
      isvideo: false
    }
  },
  methods: {
      handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    async getAdLocation() {
      const res = await this.$http.get('/nktdec/adinfo/locations')
      res.data.forEach(item => {
        const obj = {}
        obj.value = Object.keys(item)[0]
        obj.label = Object.values(item)[0]
        this.locationOption.push(obj)
      })
      console.log(this.locationOption)
    },
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handleRemove(file) {
      console.log(file)
      console.log('绑定list', this.teamlist)
      // this.$set(this.teamlist, [])
      this.teamlist.splice(0, 1)
      this.adform.coverUrl = ''
    },
    async onsubmit() {
      console.log(this.adform)
      if (this.adform.coverUrl.length === 0) {
        this.$message.error('请上传图片！')
        return false
      }
      const data = {}
      if (this.$route.params.isadd === 'true') {
        data.caption = this.adform.caption
        data.content = this.adform.content
        data.coverUrl = this.adform.coverUrl
        data.location = this.adform.location
        data.startDate = this.formatdate(this.adform.teamTimeproid[0])
        data.endDate = this.formatdate(this.adform.teamTimeproid[1])
        data.description = this.adform.description
        data.note = this.adform.note
        data.adopted = this.adform.adopted
      } else {
        data.id = this.adform.id
        data.caption = this.adform.caption
        data.content = this.adform.content
        data.coverUrl = this.adform.coverUrl
        data.location = this.adform.location
        data.startDate = this.formatdate(this.adform.teamTimeproid[0])
        data.endDate = this.formatdate(this.adform.teamTimeproid[1])
        data.description = this.adform.description
        data.note = this.adform.note
        data.adopted = this.adform.adopted
      }

      console.log(data)
      const res = await this.$http.post('/nktdec/adinfo/adinfos', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('广告设置成功！')
        this.$router.push('/adinfo')
      }
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
    async filechange(res, file, fileList) {
      // console.log('file改变了', file)
      // console.log(fileList)
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      console.log(res)
      var tmp = res.result.split('.')
      console.log(tmp[tmp.length - 1] === 'mp4')
      if (tmp[tmp.length - 1] === 'mp4') {
      this.teamlist.push({
        url: this.videoBaseUrl + res.result
      })
      this.isvideo = true
      } else {
       this.teamlist.push({
        url: this.imgBaseUrl + res.result
      })
      this.isvideo = false
      }
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.adform.coverUrl = res.result
      console.log(this.teamlist)
    },

  },
  computed: {
    AdInfo() {
      return this.$store.state.AdInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    videoBaseUrl() {
      return this.$store.state.videoBaseUrl
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '首页管理',
        index: '/swiper'
      },
      {
        name: '广告管理',
        index: '/adinfo'
      }, {
        name: '编辑广告',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    if (this.$route.params.isadd === 'false') {
      this.adform = this.AdInfo
      this.adform.teamTimeproid = []
      this.adform.teamTimeproid.push(new Date(this.adform.startDate))
      this.adform.teamTimeproid.push(new Date(this.adform.endDate))
      console.log(this.adform)
      if (this.adform.coverUrl.split('.')[this.adform.coverUrl.split('.').length - 1] === 'mp4') {
        this.teamlist.push({
          url: this.videoBaseUrl + this.adform.coverUrl
        })
        this.isvideo = true
      } else {
        this.isvideo = false
        this.teamlist.push({
          url: this.imgBaseUrl + this.adform.coverUrl
        })
      }
      console.log(this.teamlist)
    }
    this.getAdLocation()
  },
}
</script>
<style scoped>
</style>
