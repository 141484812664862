<template>
  <div style="text-align: left">轮播信息</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="轮播类型">
      <el-select
        v-model="seachForm.type"
        clearable
        size="small"
        placeholder="请选择轮播类型"
      >
        <el-option
          v-for="(item, index) in stateOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex">
    <el-button
      type="primary"
      style="display: flex"
      size="mini"
      @click="pushtoswiper"
      >新增轮播</el-button
    >
    <el-button
      type="primary"
      style="display: flex"
      size="mini"
      @click="swiperplace"
      >{{ isSortable ? "完成" : "排序" }}</el-button
    >
  </div>

  <div id="table">
    <el-table :data="tableData" style="margin-top: 30px" row-key="id">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="轮播图片">
        <template #default="scope">
          <img
            v-if="scope.row.pictureUrl"
            :src="imgBaseUrl + scope.row.pictureUrl"
            alt=""
            style="width: 80px; height: 120px; object-fit: contain"
          />
        </template>
      </el-table-column>
      <el-table-column label="轮播标题" prop="caption"></el-table-column>
      <el-table-column label="链接地址" prop="linkUrl"></el-table-column>
      <el-table-column label="轮播位置" prop="place"></el-table-column>

      <el-table-column label="轮播来源">
        <template #default="scope">
          <el-tag v-if="scope.row.sourceType === 'BANNER'">自定义 </el-tag>
          <el-tag v-if="scope.row.sourceType === 'INFO'">资讯公告 </el-tag>
          <el-tag v-if="scope.row.sourceType === 'AD'">广告 </el-tag>
          <el-tag v-if="scope.row.sourceType === 'GROUP'">团购 </el-tag>
          <el-tag v-if="scope.row.sourceType === 'LIVE'">直播 </el-tag>
          <el-tag v-if="scope.row.sourceType === 'CLASS'">云课堂 </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="展示时间" prop="showDate"></el-table-column>

      <el-table-column label="展示状态" prop="showStatus">
        <template #default="scope">
          <el-tag v-if="scope.row.adopted === true" type="success">展示</el-tag>
          <el-tag v-else-if="scope.row.adopted === false" type="info"
            >隐藏</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            style="margin-right: 10px"
            type="text"
            @click="changeshowstate(scope.row)"
            >{{ scope.row.adopted ? "隐藏" : "展示" }}</el-button
          >
          <el-button
            style="margin-right: 10px"
            type="text"
            @click="editswiper(scope.row)"
            >编辑</el-button
          >
          <el-button
            style="margin-left: 0px !important"
            type="text"
            @click="deleteswiper(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>

  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
import Sortable from 'sortablejs'
export default {
  data() {
    return {
      seachForm: {
        type: ''
      },
      tableData: [],
      stateOption: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      isSortable: false,
      sortable: {}
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '首页管理',
        index: '/swiper'
      },
      {
        name: '轮播管理',
        index: '/swiperinfo'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getswiperInfo(this.SwiperSeachForm)
    this.getactstate()
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    async swiperplace() {
      this.isSortable = !this.isSortable
      console.log(this.tableData)
      if (this.isSortable) {
        var _this = this
        this.sortable = new Sortable(document.querySelector('.el-table__body-wrapper tbody'), {
          animation: 150,
          ghostClass: 'blue-background-class',
          onEnd({ newIndex, oldIndex }) {
            const currRow = _this.tableData.splice(oldIndex, 1)[0]
            _this.tableData.splice(newIndex, 0, currRow)
            console.log(_this.tableData)
          }
        })
      } else {
        this.tableData.forEach(async (item, index) => {
          item.place = index + 1
          const res = await this.$http.post('/nktdec/bannerinfo/bannerinfos', item)
          console.log(res)
        })
        this.sortable.destroy()
      }
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/bannerinfo/bannerinfos/1' + '?pageSize=' + val + (this.seachForm.type.trim() === '' ? '' : '&type=' + this.seachForm.type))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setSwiperSeachForm',{...this.SwiperSeachForm , currentpage : '1' , pageSize: val})
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.type.trim() === '' ? '' : '&type=' + this.seachForm.type)
      const res = await this.$http.get('/nktdec/bannerinfo/bannerinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = val
      this.$store.commit('setSwiperSeachForm',{...this.SwiperSeachForm , currentpage : val , pageSize: this.pageSize+''})

    },
    query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1'
      }
      return str 
    },
    async getswiperInfo(obj) {
      var str = this.query('',obj)
      console.log(str)
      if(obj.type){
        this.seachForm.type = obj.type
      }
      const res = await this.$http.get('/nktdec/bannerinfo/bannerinfos/' + str)
      // console.log(res)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      this.$store.commit('setSwiperSeachForm',{...this.SwiperSeachForm , currentpage : obj.currentpage , pageSize: '20'})

      console.log(this.tableData)
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.type.trim() === '' ? '' : 'type=' + this.seachForm.type)
      console.log(str)
      const res = await this.$http.get('/nktdec/bannerinfo/bannerinfos/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setSwiperSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/bannerinfo/bannerinfos/1')
      console.log(res.data)
      this.seachForm.type = ''
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setSwiperSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    async getactstate() {
      const res = await this.$http.get('/nktdec/bannerinfo/sourcetypes')
      // console.log(res.data)
      for (let i = 0; i < res.data.length; i++) {
        var keys = Object.keys(res.data[i])
        var vals = Object.values(res.data[i])
        this.stateOption.push({
          key: keys[0],
          label: vals[0]
        })
      }
      // console.log(this.stateOption)
    },
    async changeshowstate(row) {
      console.log(row)
      row.adopted = !row.adopted
      const res = await this.$http.post('/nktdec/bannerinfo/bannerinfos', row)
      console.log(res)
      if (res.data.code === '1') this.$message.success('设置成功！')
      this.getswiperInfo(this.SwiperSeachForm)
    },
    editswiper(row) {
      console.log(row)
      this.$store.commit('setSwiperInfo', row)
      window.sessionStorage.setItem('SwiperInfo', JSON.stringify(row))
      this.$router.push('/changeswiper/false')
    },
    pushtoswiper() {
      this.$router.push('/changeswiper/true')
    },
    async deleteswiper(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该轮播, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/bannerinfo/bannerinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getswiperInfo(this.SwiperSeachForm)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    SwiperSeachForm() {
      return this.$store.state.SwiperSeachForm
    },
  }
}
</script>
<style scoped>
</style>
